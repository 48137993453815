import { yupResolver } from "@hookform/resolvers/yup";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { addDays, addYears } from "date-fns";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ROUTE_ADMIN_PHYSICAL_OPPORTUNITY_DETAILS } from "../../../../app/constants";
import {
  GetOrganizationsParamsActionFirstEnum,
  ReqCreatePhysicalOpportunityDto,
  ReqUpdatePhysicalOpportunityDto,
  ResPhysicalOpportunityDetailsDto,
} from "../../../../app/model/api";
import {
  useMutationCreatePhysicalOpportunity,
  useMutationUpdatePhysicalOpportunity,
} from "../../../../app/query/useMutationPhysicalStreaming";
import { useQueryAdminGetOrganizations } from "../../../../app/query/useQueryGetAdmin";
import { MuiFullscreenLoadingBackdrop } from "../../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";

const MIN_DATE = addDays(new Date(), 1);
const MAX_DATE = addYears(new Date(), 2);
interface FormData {
  readonly fullHashratePhPerSecond: number;
  readonly collateralPercentage: number;
  readonly principalAmountBtc: number;
  readonly startDate: Date;
  readonly durationDays: number;
  readonly expectedReturnPercentage: number;
  readonly structuringFeePercentage: number;
  readonly managementFeePercentage: number;
}
const ERROR_NONZERO_INTEGER_PERCENTAGE = "This value has to be a number between 1 and 100.";
const YupNonzeroIntegerPercentage = yup
  .number()
  .typeError(ERROR_NONZERO_INTEGER_PERCENTAGE)
  .required(ERROR_NONZERO_INTEGER_PERCENTAGE)
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(1, ERROR_NONZERO_INTEGER_PERCENTAGE)
  .max(100, ERROR_NONZERO_INTEGER_PERCENTAGE)
  .integer(ERROR_NONZERO_INTEGER_PERCENTAGE);

const ERROR_PERCENTAGE = "This value has to be a number between 0 and 100.";
const YupPercentage = yup
  .number()
  .min(0, ERROR_PERCENTAGE)
  .max(100, ERROR_PERCENTAGE)
  .typeError(ERROR_PERCENTAGE)
  .required(ERROR_PERCENTAGE)
  .transform((value) => (Number.isNaN(value) ? null : value));

const YupPositiveNumber = yup
  .number()
  .typeError("This value has to be a positive number.")
  .positive("This value has to be a positive number.")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .required("This value has to be a positive number.");

const YupFormSchema = yup
  .object({})
  .shape({
    fullHashratePhPerSecond: YupPositiveNumber,
    collateralPercentage: YupNonzeroIntegerPercentage,
    principalAmountBtc: YupPositiveNumber,
    startDate: yup
      .date()
      .typeError("This must be a valid future date!")
      .min(MIN_DATE, "This must be a valid future date!")
      .max(MAX_DATE, "This value can't be more than 2 years into the future.")
      .required("This value is required."),
    durationDays: YupPositiveNumber.integer("This value has to be a positive integer."),
    expectedReturnPercentage: YupPercentage,
    structuringFeePercentage: YupPercentage,
    managementFeePercentage: YupPercentage,
  })
  .required();

export const PhysicalStreamingForm = ({
  minerId,
  opportunity,
  triggerRefetch,
}: {
  minerId: string;
  opportunity?: ResPhysicalOpportunityDetailsDto;
  triggerRefetch: () => void;
}) => {
  const navigate = useNavigate();
  const [selectedLpId, setSelectedLpId] = useState<string | undefined>(opportunity?.lpId);

  const { data: orgs, isFetching: isFetchingOrgs } = useQueryAdminGetOrganizations({
    actionFirst: GetOrganizationsParamsActionFirstEnum.No,
  });

  const viableLps = orgs?.rows
    .filter((x) => x.userType === "LP" && x.onboardingStatus === "Fully onboarded")
    .map((x) => ({ id: x.id, name: x.company ? `${x.company} (${x.email})` : x.email }));

  const {
    formState: { errors, isDirty: isFormDirty },
    handleSubmit,
    watch,
    register,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(YupFormSchema),
    defaultValues: opportunity
      ? {
          fullHashratePhPerSecond: opportunity.fullHashrateThPerSecond / 1000,
          collateralPercentage: opportunity.collateralPercentage,
          principalAmountBtc: opportunity.principalAmountSatoshi / 100000000,
          startDate: opportunity.startDate ? new Date(opportunity.startDate) : addDays(new Date(), 1),
          durationDays: opportunity.durationDays,
          expectedReturnPercentage: opportunity.expectedReturnPercentage,
          structuringFeePercentage: opportunity.structuringFeePercentage,
          managementFeePercentage: opportunity.managementFeePercentage,
        }
      : {
          startDate: addDays(new Date(), 1),
          managementFeePercentage: 0,
        },
  });

  const isDirty = useMemo(() => {
    return isFormDirty || selectedLpId !== opportunity?.lpId;
  }, [isFormDirty, selectedLpId, opportunity?.lpId]);

  const startDate = watch("startDate") as Date | null;

  const { mutateAsync: createOpportunity, isLoading: isCreatingOpportunity } = useMutationCreatePhysicalOpportunity();
  const { mutateAsync: updateOpportunity, isLoading: isUpdatingOpportunity } = useMutationUpdatePhysicalOpportunity();

  const isSavingOpportunity = isCreatingOpportunity || isUpdatingOpportunity || isFetchingOrgs;

  const handleCreate = async (data: FormData) => {
    if (!selectedLpId) {
      toast.error("Please select an LP");
      return;
    }

    const request: ReqCreatePhysicalOpportunityDto = {
      minerId: minerId,
      lpId: selectedLpId,
      durationDays: data.durationDays,
      startDate: data.startDate.toISOString(),
      principalAmountSatoshi: data.principalAmountBtc * 100000000,
      fullHashrateThPerSecond: data.fullHashratePhPerSecond * 1000,
      collateralPercentage: data.collateralPercentage,
      expectedReturnPercentage: data.expectedReturnPercentage,
      structuringFeePercentage: data.structuringFeePercentage,
      managementFeePercentage: data.managementFeePercentage,
    };

    try {
      const result = await createOpportunity(request);
      toast.success("Your updates have been saved successfully.");
      navigate(ROUTE_ADMIN_PHYSICAL_OPPORTUNITY_DETAILS.replace(":id", result.data.id));
    } catch (err) {
      toast.error("There has been an issue while trying to save your changes. Please try again.");
    }
  };

  const handleUpdate = async (opportunityId: string, data: FormData) => {
    if (!selectedLpId) {
      toast.error("Please select an LP");
      return;
    }

    const request: ReqUpdatePhysicalOpportunityDto & { opportunityId: string } = {
      opportunityId: opportunityId,
      minerId: minerId,
      lpId: selectedLpId,
      durationDays: data.durationDays,
      startDate: data.startDate.toISOString(),
      principalAmountSatoshi: data.principalAmountBtc * 100000000,
      fullHashrateThPerSecond: data.fullHashratePhPerSecond * 1000,
      collateralPercentage: data.collateralPercentage,
      expectedReturnPercentage: data.expectedReturnPercentage,
      structuringFeePercentage: data.structuringFeePercentage,
      managementFeePercentage: data.managementFeePercentage,
    };

    try {
      await updateOpportunity(request);
      toast.success("Your updates have been saved successfully.");
      triggerRefetch();
    } catch (err) {
      toast.error("There has been an issue while trying to save your changes. Please try again." + err);
    }
  };

  const onSubmit = (data: FormData) => {
    if (opportunity?.id) {
      handleUpdate(opportunity.id, data);
    } else {
      handleCreate(data);
    }
  };

  const isExistingOpportunity = !!opportunity?.id;

  return (
    <Paper sx={{ padding: 2, boxShadow: 4 }}>
      <MuiFullscreenLoadingBackdrop isOpen={isSavingOpportunity} />
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Grid container item spacing={2}>
          <Grid container item>
            <Typography variant="subtitle1">
              {isExistingOpportunity ? "Update opportunity" : "Create opportunity"}
            </Typography>
          </Grid>
          {!!viableLps?.length && (
            <Grid container item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  disabled={isSavingOpportunity}
                  value={selectedLpId && viableLps.find((x) => x.id === selectedLpId)}
                  onChange={(_event, newValue) => {
                    newValue ? setSelectedLpId(newValue.id) : setSelectedLpId(undefined);
                  }}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  id="combo-box-lps"
                  options={viableLps}
                  renderInput={(params) => <TextField {...params} label="Select the LP" />}
                />
              </FormControl>
            </Grid>
          )}
          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="fullHashratePhPerSecond"
                label="Opportunity hashrate"
                placeholder="5"
                {...register("fullHashratePhPerSecond")}
                fullWidth
                variant="outlined"
                error={!!errors.fullHashratePhPerSecond}
                helperText={errors.fullHashratePhPerSecond && <Box>{errors.fullHashratePhPerSecond.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.01",
                  },
                  endAdornment: <InputAdornment position="end">PH/s</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <TextField
                id="collateralPercentage"
                label={"Required collateral as % of principal amount"}
                placeholder="5"
                {...register("collateralPercentage")}
                fullWidth
                variant="outlined"
                error={!!errors.collateralPercentage}
                helperText={errors.collateralPercentage && <Box>{errors.collateralPercentage.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.01",
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="principalAmountBtc"
                label={"Principal amount"}
                placeholder="5"
                {...register("principalAmountBtc")}
                fullWidth
                variant="outlined"
                error={!!errors.principalAmountBtc}
                helperText={errors.principalAmountBtc && <Box>{errors.principalAmountBtc.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.001",
                  },
                  endAdornment: <InputAdornment position="end">BTC</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start date"
                  format="dd/MM/yyyy"
                  value={startDate}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  onChange={(e) => {
                    setValue("startDate", e ?? addDays(new Date(), 1), { shouldDirty: true });
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      helperText: errors.startDate && errors.startDate.message,
                      error: !!errors.startDate,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="durationDays"
                label="Duration of agreement"
                placeholder="5"
                {...register("durationDays")}
                fullWidth
                variant="outlined"
                error={!!errors.durationDays}
                helperText={errors.durationDays && <Box>{errors.durationDays.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "1",
                  },
                  endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <TextField
                id="expectedReturnPercentage"
                label={"Expected annualized rate of return"}
                placeholder="5"
                {...register("expectedReturnPercentage")}
                fullWidth
                variant="outlined"
                error={!!errors.expectedReturnPercentage}
                helperText={errors.expectedReturnPercentage && <Box>{errors.expectedReturnPercentage.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.01",
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="structuringFeePercentage"
                label={"Structuring fee percentage"}
                placeholder="1.5"
                {...register("structuringFeePercentage")}
                fullWidth
                variant="outlined"
                error={!!errors.structuringFeePercentage}
                helperText={errors.structuringFeePercentage && <Box>{errors.structuringFeePercentage.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.01",
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <Stack direction="row" alignItems="center" spacing={1} width="100%">
                <Tooltip title="Not yet implemented">
                  <div style={{ width: "100%" }}>
                    <TextField
                      id="managementFeePercentage"
                      label={"Management fee percentage"}
                      placeholder="2"
                      {...register("managementFeePercentage")}
                      fullWidth
                      variant="outlined"
                      error={!!errors.managementFeePercentage}
                      helperText={errors.managementFeePercentage && <Box>{errors.managementFeePercentage.message}</Box>}
                      onWheel={(evt) => {
                        (evt.target as HTMLElement).blur(); // disable edit by scroll
                      }}
                      type="number"
                      disabled
                      InputProps={{
                        inputProps: {
                          step: "0.01",
                        },
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="This percentage will be deducted as hashrate to Block Green's pool">
                  <InfoIcon color="action" />
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} justifyContent={"flex-end"}>
            <Tooltip title={isDirty ? "" : "Please fill in the form to save."}>
              <span>
                <LoadingButton
                  size="large"
                  type="submit"
                  loading={isSavingOpportunity}
                  disabled={!isDirty}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
