import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material";
import { useLayoutEffect } from "react";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import { setToken } from "./app/config/query/clientgen";
import ToastConfig from "./app/config/toast/config";
import { PUBLIC_ROUTE } from "./app/constants";
import { useQueryGetUser } from "./app/query/useQueryGetUser";
import { router } from "./app/routes/router";
import "./assets/App.css";
import "./assets/scss/app.scss";
import Loader from "./components/atoms/Loader";
import NotionForm from "./components/molecules/NotionForm";
import ErrorPage from "./pages/ErrorPage";
import { buildGlobalTheme } from "./theme";

const globalTheme = buildGlobalTheme();
const App = () => {
  const { error, isLoading: isLoadingAuth0, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { data: user, isFetching: isFetchingUser, refetch } = useQueryGetUser();

  useLayoutEffect(() => {
    if (PUBLIC_ROUTE.includes(window.location.pathname)) return;

    const setTokenToLocalstorage = async () => {
      try {
        if (isAuthenticated) {
          const token = !window.Cypress ? await getAccessTokenSilently() : localStorage.getItem("auth0Cypress") || "";
          if (token) {
            setToken(token);
            refetch();
          }
        }
      } catch (error) {
        console.error("Failed to set authentication token:", error);
      }
    };

    setTokenToLocalstorage();
  }, [isAuthenticated, getAccessTokenSilently, refetch]);

  const isLoading =
    isLoadingAuth0 || isFetchingUser || (isAuthenticated && !PUBLIC_ROUTE.includes(window.location.pathname) && !user);

  if (error && !isAuthenticated) {
    return (
      <ErrorPage
        status="Authentication Error"
        message={
          <div>
            For help please contact{" "}
            <a target="_blank" href="mailto:support@block.green" className="hover:no-underline" rel="noreferrer">
              support@block.green
            </a>
          </div>
        }
        fullHeight
      />
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ThemeProvider theme={globalTheme}>
        <RouterProvider router={router(user)} />
      </ThemeProvider>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={ToastConfig}
      />
      <NotionForm />
    </>
  );
};

export default App;
