import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { AxisTickProps } from "@nivo/axes";
import { BarCustomLayerProps, BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { format } from "date-fns";
import Decimal from "decimal.js";
import { chunk, reverse } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { useGetHistory, useGetPrediction } from "../../../app/query/useQueryGetAdmin";
import { useGetQuerySatoshiPriceInUsd } from "../../../app/query/useQueryGetWallet";
import MinerRewardsModelDesign from "../../../assets/images/miner-rewards-model-design.png";
import { formatBtcAmount, formatUsdAmount, toSats } from "../../Loans/commons/utils";
import { ChartSkeleton } from "./ChartSkeleton";
import { AmountHeroCard } from "./HeroCard";

const CURRENT_YEAR = new Date().getFullYear();

const SELECT_OPTIONS = [30, 60, 90] as const;
type SelectOption = (typeof SELECT_OPTIONS)[number];

const MIN_HASHRATE = 100;
const MAX_HASHRATE = 10000;
const STEP_HASHRATE = 100;

const VIEWPORT_SIZE = 12;
const COLOR_HISTORY = "#14C784";
const COLOR_PREDICTION = "#FC7A53";

const COLOR_WHISKER = "#191C24";
const COLOR_MEAN = "#191C24";

type BarSchema = {
  startDate: string;
  endDate: string;
  _type: "historical" | "predicted";
  historicValue: number;
  historicMin: number;
  historicMax: number;
  predictedValue: number;
  predicted5th: number;
  predicted95th: number;
  durationDays: number;
};

export const HashrateModel = () => {
  const [hashpriceModel, setHashpriceModel] = useState<"hashprice" | "hashrate">("hashprice");
  const [duration, setDuration] = useState<SelectOption>(30);
  const [hashrateSliderPh, setHashrateSliderPh] = useState<number>(MIN_HASHRATE);
  const [timeline, setTimeline] = useState<number>(0);

  const { data, isFetching } = useGetPrediction({ range: duration });
  const { data: history, isFetching: isFetchingHistory } = useGetHistory();
  const { data: satoshisToUsd } = useGetQuerySatoshiPriceInUsd();
  const dollarsPerSatoshi = new Decimal(satoshisToUsd || 0);

  const isLoadingData = isFetching || isFetchingHistory;

  const {
    aggregatedRows,
    predictionRow,
  }: {
    aggregatedRows: BarSchema[];
    predictionRow: BarSchema | undefined;
  } = useMemo(() => {
    const hashratePh = hashpriceModel === "hashprice" ? 1 : hashrateSliderPh;

    if (!history?.data?.rows || !data?.data?.rows)
      return {
        aggregatedRows: [],
        historyRows: [],
        predictionRow: undefined,
        minDisplayedValue: 0,
        maxDisplayedValue: 0,
      };

    // Reverse first so the most recent chunk has full `duration` days
    const chunkedHistory = chunk(reverse(history.data.rows.slice()), duration);
    const historicPredictions = reverse(
      chunkedHistory.map((chunk) => {
        const startDate = format(new Date(chunk[chunk.length - 1].date), "yyyy-MM-dd");
        const endDate = format(new Date(chunk[0].date), "yyyy-MM-dd");
        const historicValue = chunk.reduce((acc, row) => acc + row.rewards * hashratePh, 0) / chunk.length;
        const historicMin = Math.min(...chunk.map((row) => row.rewards * hashratePh));
        const historicMax = Math.max(...chunk.map((row) => row.rewards * hashratePh));
        return {
          startDate,
          endDate,
          _type: "historical" as const,
          historicValue,
          historicMin,
          historicMax,
          durationDays: chunk.length,
          predictedValue: 0,
          predicted5th: 0,
          predicted95th: 0,
        };
      })
    );

    const predictionRow: BarSchema | undefined = data.data.rows.length
      ? {
          startDate: format(new Date(data.data.rows[0].date), "yyyy-MM-dd"),
          endDate: format(new Date(data.data.rows[data.data.rows.length - 1].date), "yyyy-MM-dd"),
          durationDays: data.data.rows.length,
          predictedValue:
            (data.data.rows.reduce((acc, row) => acc + row.prediction.rewards, 0) * hashratePh) / data.data.rows.length,
          predicted5th:
            (data.data.rows.reduce((acc, row) => acc + row.fifthPercentile.rewards, 0) * hashratePh) /
            data.data.rows.length,
          predicted95th:
            (data.data.rows.reduce((acc, row) => acc + row.ninetyfifthPercentile.rewards, 0) * hashratePh) /
            data.data.rows.length,
          _type: "predicted",
          historicValue: 0,
          historicMin: 0,
          historicMax: 0,
        }
      : undefined;

    return {
      aggregatedRows: [...historicPredictions, ...(predictionRow ? [predictionRow] : [])],
      predictionRow: predictionRow,
    };
  }, [hashpriceModel, hashrateSliderPh, history, data, duration]);

  useEffect(() => {
    setTimeline(Math.max(0, aggregatedRows.length - VIEWPORT_SIZE));
  }, [aggregatedRows]);

  const { displayedData, minValue, maxValue } = useMemo(() => {
    const displayedData = aggregatedRows.slice(timeline, timeline + VIEWPORT_SIZE);

    const minDisplayedValue = Math.min(...displayedData.map((row) => row.historicMin || row.predicted5th));
    const maxDisplayedValue = Math.max(...displayedData.map((row) => row.historicMax || row.predicted95th));
    const margin = (maxDisplayedValue - minDisplayedValue) / 4;

    return {
      displayedData,
      // Use a small value to prevent the 0 axis line from appearing
      minValue: Math.max(minDisplayedValue - margin, 0.0000000001),
      maxValue: maxDisplayedValue + margin,
    };
  }, [aggregatedRows, timeline]);

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    setHashrateSliderPh(newValue as number);
  };

  const handleTimelineChange = (_event: Event, newValue: number | number[]) => {
    setTimeline(newValue as number);
  };

  const { total5th, total95th, totalPrediction } = useMemo(() => {
    if (predictionRow === undefined) return { total5th: 0, total95th: 0, totalPrediction: 0 };

    const totals = {
      id: "total",
      date: "Total",
      fifth: predictionRow.predicted5th * duration,
      ninetyfifth: predictionRow.predicted95th * duration,
      prediction: predictionRow.predictedValue * duration,
    };

    return {
      total5th: toSats(totals.fifth),
      total95th: toSats(totals.ninetyfifth),
      totalPrediction: toSats(totals.prediction),
    };
  }, [predictionRow, duration]);

  const handleHashpriceModelChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment?: "hashprice" | "hashrate"
  ) => {
    if (newAlignment) setHashpriceModel(newAlignment);
  };

  const heroHeader = useMemo(() => {
    return hashpriceModel === "hashprice" ? `Total hashprice (${duration} days)` : `Total rewards (${duration} days)`;
  }, [hashpriceModel, duration]);

  return (
    <Box width={"100%"} pb={3}>
      <Container sx={{ background: "white", boxShadow: 1 }}>
        <Stack alignItems={"center"} padding={2} spacing={2}>
          <Accordion
            sx={{
              mt: 2,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Methodology</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" gutterBottom textAlign={"justify"} sx={{ mb: 2 }}>
                The Block Green miner-rewards-model aims to predict the BTC value of a given amount of hashrate over a
                given period of time. For example, with the BG model, we are able to answer the question: how much BTC
                will 10 PH/s generate in the coming 3 years. In turn, we can use this information to price opportunities
                on the platform.
              </Typography>
              <Typography variant="body1" gutterBottom textAlign={"justify"} sx={{ mb: 2 }}>
                The aim of the model is to estimate the BTC rewards (subsidy + fees) that a specified amount of hashrate
                will deliver over a chosen period of time. To achieve this objective, we have designed and implemented a
                multi-component, predictive, time-series machine learning model where each component either models
                (green boxes below) or calculates (red boxes below) a different component of the *"NHR to BTC rewards"*
                computation.
              </Typography>
              <img src={MinerRewardsModelDesign} />
              <Typography variant="body1" textAlign={"justify"} sx={{ mt: 2 }}>
                From the predicted network hashrate, we are able to derive the predicted mining difficulty which in turn
                enables a calculation of the predicted number of blocks that will, on average, be mined by the specified
                amount of hashrate. Since both BTC fees and subsidy are directly driven by the number of blocks we have
                a basis for predicting BTC rewards. It is worth noting that since subsidy still outweighs the income
                from fees, we have focussed modelling effort primarily on Network Hashrate prediction.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box display="flex" justifyContent={"flex-start"} alignItems={"center"} width={"100%"}>
            <Tooltip
              sx={{ alignItems: "center", display: "flex" }}
              title="Models are optimized for accuracy over a fixed duration. Choose the timeline of a pretrained model. At present, the model has been tuned to predict most accuractely at 3 and 6 month horizons where we have the greatest focus for opportunties on the platform."
            >
              <InfoIcon />
            </Tooltip>
            <FormControl sx={{ minWidth: 200, ml: 2 }}>
              <InputLabel id="select-valueid-label" size="small">
                Prediction model duration
              </InputLabel>
              <Select
                size="small"
                id="select_currency"
                label="Opportunity Prediction model duration"
                name="Prediction model duration"
                value={duration}
                className="select-duration"
                onChange={(e) => setDuration(e.target.value as SelectOption)}
              >
                {SELECT_OPTIONS.map((c) => (
                  <MenuItem key={c} value={c}>
                    {c} Days
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 150, ml: 2 }}>
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={hashpriceModel}
                exclusive
                onChange={handleHashpriceModelChange}
              >
                <ToggleButton value="hashprice">Hashprice</ToggleButton>
                <ToggleButton value="hashrate">Custom hashrate</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
            {hashpriceModel === "hashrate" && (
              <FormControl sx={{ minWidth: 200, ml: 2 }}>
                <Slider
                  sx={{ width: 300, mr: 2 }}
                  size="medium"
                  min={MIN_HASHRATE}
                  max={MAX_HASHRATE}
                  step={STEP_HASHRATE}
                  value={hashrateSliderPh}
                  valueLabelDisplay="on"
                  onChange={handleSliderChange}
                  valueLabelFormat={(value) => `${value} PH/s`}
                />
              </FormControl>
            )}
          </Box>
          {isLoadingData && <ChartSkeleton />}
          {!isLoadingData && (
            <Grid container item xs={12} pr={2} spacing={2}>
              <Grid container item xs={4}>
                <AmountHeroCard
                  title={heroHeader + " - 95th%"}
                  amount={formatBtcAmount(total95th)}
                  secondaryAmount={
                    dollarsPerSatoshi
                      ? `${formatUsdAmount(dollarsPerSatoshi.mul(total95th).toNumber())} at today's price`
                      : undefined
                  }
                />
              </Grid>
              <Grid container item xs={4}>
                <AmountHeroCard
                  title={heroHeader + " - Predicted"}
                  amount={formatBtcAmount(totalPrediction)}
                  secondaryAmount={
                    dollarsPerSatoshi
                      ? `${formatUsdAmount(dollarsPerSatoshi.mul(totalPrediction).toNumber())} at today's price`
                      : undefined
                  }
                />
              </Grid>
              <Grid container item xs={4}>
                <AmountHeroCard
                  title={heroHeader + " - 5th%"}
                  amount={formatBtcAmount(total5th)}
                  secondaryAmount={
                    dollarsPerSatoshi
                      ? `${formatUsdAmount(dollarsPerSatoshi.mul(total5th).toNumber())} at today's price`
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          )}
          {!isLoadingData && (
            <Box sx={{ height: 600, width: "100%", pl: 3 }} borderRadius={1} boxShadow={2} pb={3}>
              <ResponsiveBar
                data={displayedData}
                minValue={minValue}
                maxValue={maxValue}
                valueScale={{ type: "linear", min: minValue, max: maxValue, clamp: true }}
                keys={["historicValue", "predictedValue"]}
                indexBy={(value) => `${value.startDate},${value.endDate}`}
                animate={false}
                margin={{ top: 60, right: 90, bottom: 130, left: 80 }}
                padding={0.3}
                colors={"rgba(0,0,0,0)"}
                tooltip={BarChartTooltip}
                axisLeft={{
                  tickRotation: 0,
                  legend: hashpriceModel === "hashprice" ? "Hashprice (BTC / PH / day)" : "Rewards (BTC / day)",
                  legendOffset: -70,
                  legendPosition: "middle",
                }}
                axisBottom={{
                  tickRotation: 30,
                  legend: "",
                  legendOffset: 0,
                  legendPosition: "middle",
                  renderTick: (props: AxisTickProps<string>) => {
                    const [startDate, endDate] = props.value.toString().split(",");
                    return (
                      <g transform={`translate(${props.x},${props.y})`}>
                        <text
                          x={-40}
                          y={20}
                          dy={0}
                          textAnchor="start"
                          transform={`rotate(${props.rotate})`}
                          style={{
                            fontFamily: "PP Neue Machina",
                            fontSize: 12,
                            fill: "rgba(0,0,0,0.6)",
                          }}
                        >
                          {dateRangeLabel(startDate, endDate)}
                        </text>
                      </g>
                    );
                  },
                }}
                enableLabel={false}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fontFamily: "PP Neue Machina",
                      },
                    },
                  },
                  legends: {
                    text: {
                      fontFamily: "PP Neue Machina",
                    },
                  },
                }}
                layers={["grid", "axes", "bars", "markers", "annotations", (props) => <CandlestickLayer {...props} />]}
              />

              <Box width="100%" pl={9} pr={10} mt={-4}>
                <Slider
                  sx={{ width: "100%" }}
                  size="medium"
                  min={0}
                  max={aggregatedRows.length - VIEWPORT_SIZE}
                  step={1}
                  color={"secondary"}
                  value={timeline}
                  onChange={handleTimelineChange}
                  valueLabelFormat={(value) => `${value} PH/s`}
                />
              </Box>
            </Box>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

const formatDate = (date: string) => {
  const d = new Date(date);
  return format(d, d.getFullYear() === CURRENT_YEAR ? "d MMM" : "d MMM yy");
};

const dateRangeLabel = (startDate: string, endDate: string) => `${formatDate(startDate)} - ${formatDate(endDate)}`;

const BarChartTooltip: FC<BarTooltipProps<BarSchema>> = ({ id, value, data }) => {
  if (id === "historicValue") {
    return (
      <Paper sx={{ p: 1 }}>
        <Typography variant="body2">
          <strong>{dateRangeLabel(data.startDate, data.endDate)}</strong>
        </Typography>
        <Typography variant="body2">Mean Daily Rewards: {value.toFixed(6)} BTC</Typography>
        <Typography variant="body2">Min Daily Rewards: {data.historicMin.toFixed(6)} BTC</Typography>
        <Typography variant="body2">Max Daily Rewards: {data.historicMax.toFixed(6)} BTC</Typography>
      </Paper>
    );
  } else {
    return (
      <Paper sx={{ p: 1 }}>
        <Typography variant="body2">
          <strong>{dateRangeLabel(data.startDate, data.endDate)}</strong>
        </Typography>
        <Typography variant="body2">Predicted Daily Rewards: {value.toFixed(6)} BTC</Typography>
        <Typography variant="body2">5th Percentile: {data.predicted5th.toFixed(6)} BTC</Typography>
        <Typography variant="body2">95th Percentile: {data.predicted95th.toFixed(6)} BTC</Typography>
      </Paper>
    );
  }
};

const CandlestickLayer: FC<BarCustomLayerProps<BarSchema>> = ({ bars, yScale }) => {
  const margin = 10;
  return (
    <>
      {bars.map((bar) => {
        const data = bar.data.data;
        const isPrediction = !!data.predictedValue;
        if (bar.height === 0) return null;

        if (isPrediction) {
          const x = bar.x + bar.width / 2;
          const maxY = yScale(data.predicted5th);
          const minY = yScale(data.predicted95th);

          return (
            <g key={bar.key}>
              <rect
                x={bar.x + margin}
                y={maxY}
                width={bar.width - 2 * margin}
                height={minY - maxY}
                fill={COLOR_PREDICTION}
                strokeWidth={0.5}
              />
              <line
                x1={bar.x + margin}
                x2={bar.x + bar.width - margin}
                y1={bar.y}
                y2={bar.y}
                stroke={COLOR_WHISKER}
                strokeWidth={1}
                strokeDasharray="3 3"
              />
              {/* Vertical whisker line */}
              <line x1={x} x2={x} y1={maxY} y2={minY} stroke={COLOR_WHISKER} strokeWidth={1} />
              {/* Min whisker cap */}
              <line
                x1={bar.x + margin}
                x2={bar.x + bar.width - margin}
                y1={maxY}
                y2={maxY}
                stroke={COLOR_WHISKER}
                strokeWidth={1}
              />
              {/* Max whisker cap */}
              <line
                x1={bar.x + margin}
                x2={bar.x + bar.width - margin}
                y1={minY}
                y2={minY}
                stroke={COLOR_WHISKER}
                strokeWidth={1}
              />
            </g>
          );
        } else {
          const minY = yScale(data.historicMin);
          const maxY = yScale(data.historicMax);

          return (
            <g key={bar.key} id={`thing-${bar.index}`}>
              <rect
                x={bar.x + margin}
                y={maxY}
                width={bar.width - 2 * margin}
                height={minY - maxY}
                fill={COLOR_HISTORY}
                strokeWidth={0.5}
              />
              <line
                x1={bar.x + margin}
                x2={bar.x + bar.width - margin}
                y1={bar.y}
                y2={bar.y}
                stroke={COLOR_MEAN}
                strokeWidth={1}
              />
            </g>
          );
        }
      })}
    </>
  );
};
