import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

export const AdminPhysicalOpportunityRewards = ({ opportunityId: _opportunityId }: { opportunityId: string }) => {
  // TODO: Implement physical opportunity rewards
  return (
    <Box width={"100%"} mt={1}>
      <Stack>
        <Skeleton width="100%" height={100} />
        <Skeleton width="100%" height={200} />
      </Stack>
    </Box>
  );
};
