import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { ERROR_DEFAULT_MESSAGE } from "../../app/constants";
import { isCustomError } from "../../app/helper/errors";
import {
  amountFormat,
  amountFormatSatoshisToBTC,
  copyToClipboard,
  downloadFile,
  getTransactionStatusClass,
  reduceHash,
} from "../../app/helper/utils";
import {
  AuthUserResponseDto,
  AuthUserResponseDtoUserTypeEnum,
  GetWalletDepositTxsParamsSortEnum,
  ResTransactionDtoTransactionTypeEnum,
  Sort,
} from "../../app/model/api";
import { useMutationExportOrganizationCsv } from "../../app/query/useMutationCsv";
import { useQueryGetWalletDeposit } from "../../app/query/useQueryGetWallet";
import { ReactComponent as CopyIcon } from "../../assets/images/copy-icon.svg";
import heroBalanceBck from "../../assets/images/hero-bck-2.png";
import { ReactComponent as TxIcon } from "../../assets/images/txLink-icon.svg";
import HeroBox from "../../components/atoms/HeroBox";
import HeroBoxText from "../../components/atoms/HeroBoxText";
import Loader from "../../components/atoms/Loader";
import SectionTitle from "../../components/atoms/SectionTitle";
import TableDateCell from "../../components/atoms/TableDateCell";
import Pagination from "../../components/molecules/Pagination";
import Table from "../../components/organisms/Table";
import "./style.scss";

const Deposit = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);

  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [direction, setDirection] = useState<Sort>(Sort.Desc);

  const { data, isFetching } = useQueryGetWalletDeposit({
    offset: (page - 1) * perPage,
    limit: perPage,
    sort: direction === Sort.Desc ? GetWalletDepositTxsParamsSortEnum.Desc : GetWalletDepositTxsParamsSortEnum.Asc,
  });
  const { mutateAsync: exportOrganizationCsv } = useMutationExportOrganizationCsv();

  const notify = (message: string) => toast.success(message);

  const handleCopy = (text: string) => {
    copyToClipboard(text);
    notify("Address copied to clipboard");
  };

  const handleDownloadCsv = async () => {
    try {
      const { url } = await exportOrganizationCsv({
        includeDeposits: true,
        includeWithdrawals: false,
        includeInternal: false,
      });
      downloadFile(url);
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  const paginationPages = data?.count ? Math.ceil(data?.count / perPage) : 0;
  const userWalletAddress = `${user?.data?.walletAddress?.substring(0, 7)}...${user?.data?.walletAddress?.substring(
    user?.data?.walletAddress.length - 7
  )}`;

  return (
    <div>
      <div
        className={`hero-deposit ${
          user?.data?.userType === AuthUserResponseDtoUserTypeEnum.Miner
            ? "!bg-hero-deposit-miner"
            : "!bg-hero-deposit-lp"
        }  `}
      >
        <div className="container-custom mx-auto !py-14">
          <div className="grid grid-cols-2 gap-4">
            <HeroBox title="your address" backgroundImage={heroBalanceBck}>
              <div className="flex gap-3 justify-between w-full">
                <div className="crypto-value">{userWalletAddress}</div>
                <div className="flex gap-2">
                  <button className="bg-gray-100 px-3" onClick={() => handleCopy(user?.data?.walletAddress || "")}>
                    <CopyIcon />
                  </button>
                </div>
              </div>
            </HeroBox>
            <HeroBoxText />
          </div>
        </div>
      </div>
      <SectionTitle title="Recent Deposits" />
      <div className="container-custom mx-auto !pt-14 !pb-16">
        <Table onExportCsv={() => handleDownloadCsv()}>
          <Table.Head>
            <Table.TR>
              <Table.TH>Status</Table.TH>
              <Table.TH className="!text-center" onSort={(direction) => setDirection(direction)}>
                Date & Time
              </Table.TH>
              <Table.TH className="!text-end">Sent From</Table.TH>
              <Table.TH className="!text-end">Amount</Table.TH>
              <Table.TH className="!text-end">TX Link</Table.TH>
            </Table.TR>
          </Table.Head>
          <Table.Body>
            {data?.rows &&
              data?.rows.map((item, key) => {
                return (
                  <Table.TR key={key}>
                    <Table.TD>
                      <div className={`status-box ${getTransactionStatusClass(item.status)}`}>{item.status}</div>
                    </Table.TD>
                    <Table.TD>{item.datetime ? <TableDateCell date={item.datetime} /> : <div>UNKNOWN</div>}</Table.TD>
                    <Table.TD className="!text-end">{reduceHash(item.fromAddr)}</Table.TD>
                    <Table.TD>
                      <div className="text-end">
                        <div className="flex flex-col">
                          <span
                            className={classNames("btc-value", {
                              "!text-[#FC7A53]":
                                item.transactionType === ResTransactionDtoTransactionTypeEnum.Withdrawal,
                              "!text-[#31DF8B]":
                                item.transactionType !== ResTransactionDtoTransactionTypeEnum.Withdrawal,
                            })}
                          >
                            {`${
                              item.transactionType === ResTransactionDtoTransactionTypeEnum.Withdrawal ? "-" : "+"
                            }${amountFormatSatoshisToBTC(item.amountBtc)}`}{" "}
                            BTC
                          </span>
                          <span className="amount-value">{`${
                            item.transactionType === ResTransactionDtoTransactionTypeEnum.Withdrawal ? "-" : "+"
                          }${amountFormat(item.amountUsd)}`}</span>
                        </div>
                      </div>
                    </Table.TD>
                    <Table.TD className="text-end">
                      <Link className="flex justify-end" to={item.txLink} target="_blank">
                        <TxIcon width={20} />
                      </Link>
                    </Table.TD>
                  </Table.TR>
                );
              })}

            {data && data?.rows?.length === 0 && !isFetching && (
              <Table.TR>
                <Table.TD colSpan={5} className="relative text-center !h-[150px]">
                  No results.
                </Table.TD>
              </Table.TR>
            )}
            {isFetching && (
              <Table.Loader>
                <Table.TD colSpan={5} className="relative !h-[865px]">
                  <Loader />
                </Table.TD>
              </Table.Loader>
            )}
          </Table.Body>
        </Table>
        {paginationPages > 1 && (
          <Pagination
            page={page}
            paginationPages={paginationPages}
            onPrev={() => setPage((prev: number) => prev - 1)}
            onNext={() => setPage((prev: number) => prev + 1)}
          />
        )}
      </div>
    </div>
  );
};

export default Deposit;
