import { ResponsiveLine, Serie } from "@nivo/line";

interface LineChartProps {
  data: Serie[];
  colors: string[];
  height?: number;
  xTitle?: string;
}

const LineChart = ({ data, colors, xTitle, height = 420 }: LineChartProps) => {
  return (
    <div style={{ height, maxWidth: "100%", backgroundColor: "#ffffff" }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 150, bottom: 50, left: 100 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 25,
          tickRotation: 0,
          legend: "",
          legendOffset: 39,
          legendPosition: "middle",
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legend: xTitle,
          legendOffset: -86,
          legendPosition: "middle",
        }}
        enableGridX={false}
        enableGridY={false}
        colors={colors}
        pointSize={10}
        pointColor={{ from: "color", modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ theme: "background" }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.45}
        useMesh={true}
        tooltip={({ point }) => {
          return (
            <div
              style={{
                background: "white",
                padding: "7px 10px",
                border: "1px solid #ccc",
              }}
            >
              <div>{point.data.y.toString()} BTC</div>
            </div>
          );
        }}
        legends={[
          {
            anchor: "top-right",
            direction: "column",
            justify: false,
            translateX: 170,
            translateY: -8,
            itemsSpacing: 4,
            itemDirection: "left-to-right",
            itemWidth: 150,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        motionConfig="molasses"
      />
    </div>
  );
};

export default LineChart;
