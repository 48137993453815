import React from "react";
import { Typography, Box, Stack } from "@mui/material";
import { MiningPoolCredentialsDto } from "../../../app/model/api";

type Props = {
  config: MiningPoolCredentialsDto;
};

export const ReadOnlyPoolConfig: React.FC<Props> = ({ config }) => {
  return (
    <Box sx={{ p: 1, bgcolor: "background.paper", borderRadius: 1 }}>
      <Stack spacing={2}>
        <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
          <Box component="span" sx={{ fontWeight: "bold", minWidth: 140 }}>
            Pool URL:
          </Box>
          {config.poolUrl}
        </Typography>

        <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
          <Box component="span" sx={{ fontWeight: "bold", minWidth: 140 }}>
            Worker ID:
          </Box>
          {config.workerIdMode?.charAt(0).toUpperCase() + config.workerIdMode?.slice(1)}
        </Typography>
      </Stack>
    </Box>
  );
};
