import { LoadingButton } from "@mui/lab";
import { Box, FormControl, InputLabel, Link, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import Decimal from "decimal.js";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ERROR_DEFAULT_MESSAGE, SATOSHIS_TO_BTC } from "../../../app/constants";
import { isCustomError } from "../../../app/helper/errors";
import { downloadFile, reduceHash } from "../../../app/helper/utils";
import { GetMinerOpportunityHistoryTxsParamsTxtypesEnum, ResTransactionDto } from "../../../app/model/api";
import { useGetQueryMinerOpportunityHistory } from "../../../app/query/useGetQueryOpportunity";
import { useMutationExportOpportunityCsv } from "../../../app/query/useMutationCsv";
import { ReactComponent as TxIcon } from "../../../assets/images/txLink-icon.svg";
import { TableAmountCell } from "../../atoms/TableAmountCell";

const APPROVAL_ADMIN_TRANSACTION_TYPES: {
  value: GetMinerOpportunityHistoryTxsParamsTxtypesEnum | "all";
  label: string;
}[] = [
  {
    value: "all",
    label: "All",
  },
  {
    value: GetMinerOpportunityHistoryTxsParamsTxtypesEnum.CollateralDeposit,
    label: "Collateral Deposit",
  },
  {
    value: GetMinerOpportunityHistoryTxsParamsTxtypesEnum.OpportunityFunding,
    label: "Opportunity Funding",
  },
  {
    value: GetMinerOpportunityHistoryTxsParamsTxtypesEnum.RewardDistribution,
    label: "Reward Distribution",
  },
  {
    value: GetMinerOpportunityHistoryTxsParamsTxtypesEnum.LiquidityRefund,
    label: "Liquidity Refund",
  },
  {
    value: GetMinerOpportunityHistoryTxsParamsTxtypesEnum.CollateralRelease,
    label: "Collateral Release",
  },
  {
    value: GetMinerOpportunityHistoryTxsParamsTxtypesEnum.LiquidityDelivery,
    label: "Liquidity Delivery",
  },
  {
    value: GetMinerOpportunityHistoryTxsParamsTxtypesEnum.ExcessRewardRelease,
    label: "Excess Reward Release",
  },
  {
    value: GetMinerOpportunityHistoryTxsParamsTxtypesEnum.RewardDelivery,
    label: "Reward Delivery",
  },
];

interface TransactionHistoryTableProps {
  allowedTransactionTypes?: GetMinerOpportunityHistoryTxsParamsTxtypesEnum[];
}

export const TransactionHistoryTable = ({ allowedTransactionTypes }: TransactionHistoryTableProps) => {
  const { id } = useParams();

  const [transactionHistoryFilter, setTransactionHistoryFilter] = useState<
    "all" | GetMinerOpportunityHistoryTxsParamsTxtypesEnum[]
  >("all");

  const availableTransactionTypes = useMemo(
    () =>
      allowedTransactionTypes
        ? [
            { value: "all", label: "All" },
            ...allowedTransactionTypes.map((type) => ({
              value: type,
              label: APPROVAL_ADMIN_TRANSACTION_TYPES.find((t) => t.value === type)?.label || type,
            })),
          ]
        : APPROVAL_ADMIN_TRANSACTION_TYPES,
    [allowedTransactionTypes]
  );

  const { data, isFetching } = useGetQueryMinerOpportunityHistory(id ?? "", {
    txtypes: transactionHistoryFilter === "all" ? allowedTransactionTypes : transactionHistoryFilter,
  });

  const rows = useMemo(
    () =>
      data?.rows.map((x) => ({
        ...x,
        datetime: x.datetime ? format(new Date(x.datetime), "dd MMM yyyy HH:mm") : undefined,
      })) ?? [],
    [data?.rows]
  );

  const { mutateAsync: exportCsv, isLoading: isExporting } = useMutationExportOpportunityCsv();

  const handleDownloadCsv = async () => {
    try {
      const { url } = await exportCsv({
        opportunityId: id ?? "",
      });
      downloadFile(url);
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  const columns: GridColDef<ResTransactionDto>[] = useMemo(
    () => [
      {
        field: "status",
        headerName: "Status",
        sortable: false,
        disableColumnMenu: true,
        width: 120,
        display: "flex",
      },
      {
        field: "addresses",
        headerName: "Addresses",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => {
          const fromAddr = <span className="text-[#BC92FF]">{reduceHash(params.row.fromAddr ?? "")}</span>;
          const toAddr = <span className="text-[#BC92FF]">{reduceHash(params.row.toAddr ?? "")}</span>;
          return (
            <Box>
              <div>
                <b>From:</b>{" "}
                {params.row.fromName ? (
                  <>
                    {params.row.fromName} ({fromAddr})
                  </>
                ) : (
                  fromAddr
                )}
              </div>
              <div>
                <b>To:</b>{" "}
                {params.row.toName ? (
                  <>
                    {params.row.toName} ({toAddr})
                  </>
                ) : (
                  toAddr
                )}
              </div>
            </Box>
          );
        },
      },
      {
        field: "transactionType",
        headerName: "Type",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
      },
      {
        field: "datetime",
        headerName: "Date & time",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        display: "flex",
        renderCell: (params) => (
          <TableAmountCell
            type={params.row.transactionType}
            amountBtc={new Decimal(params.row.amountBtc).div(SATOSHIS_TO_BTC).toNumber()}
            amountUsd={params.row.amountUsd}
          />
        ),
      },
      {
        field: "txLink",
        headerName: "Tx link",
        sortable: false,
        disableColumnMenu: true,
        align: "right",
        headerAlign: "right",
        width: 150,
        display: "flex",
        renderCell: (params) => {
          return (
            <Box maxWidth={"100%"} display="flex" justifyContent={"flex-end"} alignItems={"center"}>
              <Link href={params.row.txLink} target="_blank">
                <TxIcon width={20} />
              </Link>
            </Box>
          );
        },
      },
    ],
    []
  );

  return (
    <Stack spacing={1} sx={{ position: "relative", height: "100%" }}>
      <Box display="flex" alignItems={"center"} gap={1}>
        <Typography variant="h5">Transaction history</Typography>
        <LoadingButton loading={isExporting || isFetching} onClick={handleDownloadCsv}>
          Export all transactions
        </LoadingButton>
      </Box>
      <FormControl sx={{ width: 250 }}>
        <InputLabel size="small" id="select-type">
          Transaction type
        </InputLabel>
        <Select
          labelId="select-type"
          label={"Transaction type"}
          id="select-type"
          size="small"
          value={transactionHistoryFilter === "all" ? "all" : transactionHistoryFilter[0]}
          onChange={(newValue) => {
            const value = newValue.target.value;
            setTransactionHistoryFilter(
              value === "all" ? "all" : [value as GetMinerOpportunityHistoryTxsParamsTxtypesEnum]
            );
          }}
        >
          {availableTransactionTypes.map((action) => (
            <MenuItem key={"key-select-type" + action.value} value={action.value}>
              {action.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DataGridPro
        rows={rows}
        columns={columns}
        loading={isFetching}
        sortingMode="client"
        disableColumnFilter={true}
        pageSizeOptions={[25, 50, 100]}
        rowHeight={67}
        rowSelection={false}
        paginationMode="client"
        pagination
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        sx={{
          bgcolor: "white",
          boxShadow: 3,
          height: "50vh",
          width: "100%",
          padding: 1,
          "& .MuiDataGrid-main": {
            // Prevent layout shifts during loading
            minHeight: 200,
          },
        }}
      />
    </Stack>
  );
};
