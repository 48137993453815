import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import toast from "react-hot-toast";
import { ERROR_DEFAULT_MESSAGE, ERROR_FORM_VALIDATION_MESSAGE, WHITELIST_SCHEMA } from "../../app/constants";
import { isCustomError } from "../../app/helper/errors";
import { getError } from "../../app/helper/utils";
import {
  AuthUserResponseDto,
  AuthUserResponseDtoUserTypeEnum,
  RejectOrAcceptProposalDtoActionTypeEnum,
  AuthUserResponseDtoRolesEnum,
  WhitelistAddressApprovalDto,
  WhitelistAddressApprovalDtoVerdictEnum,
  WhitelistAddressProposalDto,
} from "../../app/model/api";
import { Permission } from "../../app/model/user.type";
import { useQueryGetManagerCount } from "../../app/query/useGetQueryOrganization";
import {
  useMutationApproveOrRejectWhitelistProposal,
  useMutationRemoveWhitelist,
  useMutationWhitelist,
} from "../../app/query/useMutationUser";
import { useQueryGetWhitelist, useQueryWhiteListProposal } from "../../app/query/useQueryGetUser";
import { ReactComponent as CheckIcon } from "../../assets/images/check-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import Loader from "../../components/atoms/Loader";
import SectionTitle from "../../components/atoms/SectionTitle";
import Tooltip from "../../components/atoms/Tooltip";
import Pagination from "../../components/molecules/Pagination";
import Form from "../../components/organisms/Form";
import Modal from "../../components/organisms/Modal";
import Table from "../../components/organisms/Table";
import "./style.scss";

enum Action {
  APPROVE = "approve",
  REJECT = "reject",
  ADD = "add",
  REMOVE = "remove",
}

interface ModalDataProps {
  open: boolean;
  title: string;
  description: ReactNode | null;
  payload?: {
    id?: string;
    actionType?: Action;
    address?: string;
    label?: string;
  };
}

interface Errors {
  [key: string]: string | null;
}

interface FormData {
  walletName: string;
  walletAddress: string;
}

const initModalData = { open: false, title: "", description: null, address: "", label: "" };
const initFormData = { walletName: "", walletAddress: "" };

const WhiteListAddress = () => {
  const [pageWhitelistProposal, setPageWhitelistProposal] = useState<number>(1);
  const [pageWhitelistAddress, setPageWhitelistAddress] = useState<number>(1);
  const [perPage] = useState<number>(10);

  const queryClient = useQueryClient();
  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);
  const hasUserWalletManagerRole =
    user?.data?.roles?.some((item) => item === AuthUserResponseDtoRolesEnum.WalletManager) || false;
  const { mutateAsync: addWhitelist, isLoading } = useMutationWhitelist();
  const { mutateAsync: removeWhitelistAddress } = useMutationRemoveWhitelist();
  const { mutateAsync: approveOrRejectProposal } = useMutationApproveOrRejectWhitelistProposal();

  const { data: totalManagers } = useQueryGetManagerCount();
  const { data: whitelistProposal, refetch: refetchWhitelistProposal } = useQueryWhiteListProposal(
    {
      offset: (pageWhitelistProposal - 1) * perPage,
      limit: perPage,
    },
    hasUserWalletManagerRole
  );
  const {
    data: whitelistAddress,
    isFetching: isFetchingWhitelistAddress,
    refetch,
  } = useQueryGetWhitelist({
    offset: (pageWhitelistAddress - 1) * perPage,
    limit: perPage,
  });

  const [errors, setErrors] = useState<Errors>({});
  const [formData, setFormData] = useState<FormData>(initFormData);
  const [modalData, setModalData] = useState<ModalDataProps>(initModalData);

  const notify = (message: string) => toast.error(message);

  const requiredWhitelistApprovers = user?.data?.requiredWhitelistApprovers || 0;
  const onlyOneApproverRequired = requiredWhitelistApprovers <= 1;

  const handleSubmit = (data: FormData) => {
    WHITELIST_SCHEMA.validate(data, { abortEarly: false })
      .then(async (valid) => {
        try {
          const modalData: ModalDataProps = {
            open: true,
            title: requiredWhitelistApprovers === 1 ? "Confirm whitelist addition" : "Confirm new whitelist proposal",
            description: null,
            payload: {
              address: valid.walletAddress,
              label: valid.walletName,
              actionType: Action.ADD,
            },
          };

          if (onlyOneApproverRequired) {
            modalData.description = (
              <p>
                <span className="text-yellow-500">Warning!</span>{" "}
                {totalManagers?.walletManagerCount === 1 && onlyOneApproverRequired
                  ? "You are the only wallet manager in your enterprise"
                  : "Your enterprise only requires one approver for access changes"}
                , so these changes are applied without another approver after a 24h delay. For security reasons, we
                strongly recommend adding more Wallet Managers and increasing the number of approvals required for
                access changes.
              </p>
            );
          } else {
            modalData.description = (
              <>
                <p>
                  {requiredWhitelistApprovers - 1} other Wallet Managers in your enterprise must approve this proposal.
                </p>
              </>
            );
          }

          setModalData(modalData);
        } catch (error: unknown) {
          if (isCustomError(error)) toast.error(error.error.message);
          else toast.error(ERROR_DEFAULT_MESSAGE);
        }
      })
      .catch((err) => {
        const errors = err.inner.reduce((prev: Errors, current: { path: string; message: string }) => {
          return { ...prev, [current.path]: current.message };
        }, {});

        setErrors(errors);
        notify(ERROR_FORM_VALIDATION_MESSAGE);
      });
  };

  const handleConfirmAction = async () => {
    try {
      if (modalData.payload?.actionType === Action.ADD && modalData?.payload.address && modalData?.payload.label) {
        await addWhitelist({ address: modalData.payload.address, name: modalData.payload.label });
        refetchWhitelistProposal();
      } else if (modalData.payload?.actionType === Action.REMOVE && modalData?.payload?.id) {
        await removeWhitelistAddress(modalData.payload.id);
      } else if (
        modalData?.payload &&
        (modalData.payload?.actionType === Action.APPROVE || modalData.payload?.actionType === Action.REJECT)
      ) {
        await approveOrRejectProposal({
          accessProposalId: modalData.payload.id as string,
          actionType:
            modalData.payload?.actionType === Action.APPROVE
              ? RejectOrAcceptProposalDtoActionTypeEnum.Approve
              : RejectOrAcceptProposalDtoActionTypeEnum.Reject,
        });
        refetchWhitelistProposal();
      }

      refetch();
    } catch (error: unknown) {
      if (isCustomError(error)) toast.error(error.error.message);
      else toast.error(ERROR_DEFAULT_MESSAGE);
    }

    setErrors({});
    setFormData(initFormData);
    setModalData(initModalData);
  };

  const getCurrentApprovals = (approvals: WhitelistAddressApprovalDto[]): number => {
    return approvals.length === 0
      ? 0
      : approvals.filter((item) => item.verdict === WhitelistAddressApprovalDtoVerdictEnum.Approved).length;
  };

  const userHasAlreadyRespondedToProposal = (approvals: WhitelistAddressApprovalDto[]): boolean => {
    return approvals.some((item) => item.user.id === user?.data?.id);
  };

  const canUserCreateWhitelist = user?.data?.permissions?.includes(Permission.CREATE_WHITELIST_PROPOSAL);
  const whitelistPaginationPages = whitelistProposal?.count ? Math.ceil(whitelistProposal?.count / perPage) : 0;
  const whitelistAddressPaginationPages = whitelistAddress?.count ? Math.ceil(whitelistAddress?.count / perPage) : 0;

  return (
    <div className="whitelist-address-page-wrapper">
      <div
        className={classNames("hero-whitelist-address", {
          "hero-whitelist-address--lp": user?.data?.userType === AuthUserResponseDtoUserTypeEnum.LP,
          "hero-whitelist-address--miner": user?.data?.userType === AuthUserResponseDtoUserTypeEnum.Miner,
        })}
      ></div>
      <SectionTitle title="Whitelist New Address" />
      <div className="container-custom mx-auto">
        <div className="mt-3 mb-12 text-[#667085]">
          <span>Whitelisting procedure:</span>
          <ol className="pl-2 my-2 list-inside list-disc">
            <li>
              Assign a unique label for the wallet to be whitelisted in "Wallet Name" and enter the corresponding
              address in "Wallet Address".
            </li>
            <li>
              Our Block Green Support team will liaise with the designated approver in your organization through email.
            </li>
            <li>
              Following their verification and a mandatory 24-hour hold period, the address will qualify for
              withdrawals.
            </li>
          </ol>
          <span>
            Note: Enhancements to this procedure are anticipated in Q3 as part of our ongoing process improvement
            efforts.
          </span>
        </div>

        <Form
          onSubmit={(data) => (canUserCreateWhitelist ? handleSubmit(data) : null)}
          onChange={(name, value) => {
            setErrors((prev: Errors) => {
              if (value) {
                return { ...prev, [name]: null };
              }

              return prev;
            });
            setFormData((prev: FormData) => {
              return { ...prev, [name]: value };
            });
          }}
        >
          <div className="grid grid-flow-col auto-cols-max gap-5 items-center">
            <div className="w-[280px]">
              <h4>Wallet Name</h4>
            </div>
            <div className="w-[512px]">
              <Input
                label=""
                disabled={isLoading || !canUserCreateWhitelist}
                placeholder="My Exchange Wallet"
                className=" w-full"
                name="walletName"
                value={formData?.walletName || ""}
                error={getError(errors, "walletName")}
              />
            </div>
          </div>

          <hr className="my-6 h-px !bg-gray-200 border-0 dark:bg-gray-700" />

          <div className="grid grid-flow-col auto-cols-max gap-5 items-center">
            <div className="w-[280px]">
              <h4>Wallet Address</h4>
            </div>
            <div className="w-[512px]">
              <Input
                label=""
                disabled={isLoading || !canUserCreateWhitelist}
                placeholder="1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX"
                className=" w-full"
                name="walletAddress"
                value={formData?.walletAddress || ""}
                error={getError(errors, "walletAddress")}
              />
            </div>
          </div>

          <div className="grid grid-flow-col auto-cols-max gap-5 mt-8 mb-14">
            <div className="w-[280px] "></div>
            <>
              <Button
                type="submit"
                label="Add Address"
                className="w-[172px] px-1 text-white"
                backgroundColor="#8F49FD"
                disabled={isLoading}
                notAllowed={!canUserCreateWhitelist}
              />
              {!canUserCreateWhitelist ? (
                <Tooltip
                  width={25}
                  height={25}
                  className="w-[300px] left-[-137px] bottom-[43px]"
                  content="You do not have permission to perform this action. To request access, contact your organization administrators"
                />
              ) : null}
            </>
          </div>
        </Form>
      </div>

      {whitelistProposal && whitelistProposal?.count > 0 ? (
        <>
          <SectionTitle title="Pending Address Changes" />
          <div className="container-custom mx-auto !pt-14 !pb-16">
            <Table>
              <Table.Head>
                <Table.TR>
                  <Table.TH>Address</Table.TH>
                  <Table.TH>Label</Table.TH>
                  <Table.TH>Status</Table.TH>
                  <Table.TH>Proposed By</Table.TH>
                  <Table.TH />
                </Table.TR>
              </Table.Head>
              <Table.Body>
                {whitelistProposal?.rows?.map((item: WhitelistAddressProposalDto) => {
                  return (
                    <Table.TR key={item.id}>
                      <Table.TD>{item.address}</Table.TD>
                      <Table.TD>{item.name}</Table.TD>
                      <Table.TD>
                        <p className="!text-inherit">
                          {getCurrentApprovals(item.approval)}/{requiredWhitelistApprovers} approvals
                        </p>
                        {getCurrentApprovals(item.approval) === requiredWhitelistApprovers ? (
                          <p className="!text-inherit">24h hold</p>
                        ) : null}
                      </Table.TD>
                      <Table.TD>
                        {item.proposerUser.firstName} {item.proposerUser.lastName}
                      </Table.TD>
                      <Table.TD>
                        <div className="flex gap-2 justify-end">
                          <div className="inline-block w-auto">
                            <Button
                              backgroundColor="#8f49fd"
                              className="w-[36px] p-0 text-white"
                              notAllowed={userHasAlreadyRespondedToProposal(item.approval)}
                              label=""
                              icon={<CheckIcon />}
                              onClick={() =>
                                setModalData({
                                  open: true,
                                  title: "Whitelist proposal",
                                  description: "Are you sure you want to accept the proposal?",
                                  payload: { id: item.id, actionType: Action.APPROVE },
                                })
                              }
                            />
                          </div>
                          <div className="inline-block w-auto">
                            <Button
                              backgroundColor="#8f49fd"
                              className="w-[36px] p-0 text-white"
                              label=""
                              notAllowed={!hasUserWalletManagerRole}
                              icon={<CloseIcon />}
                              onClick={() =>
                                hasUserWalletManagerRole
                                  ? setModalData({
                                      open: true,
                                      title: "Whitelist proposal",
                                      description: "Are you sure you want to reject the proposal?",
                                      payload: { id: item.id, actionType: Action.REJECT },
                                    })
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </Table.TD>
                    </Table.TR>
                  );
                })}
              </Table.Body>
            </Table>
            {whitelistPaginationPages > 1 && (
              <Pagination
                page={pageWhitelistProposal}
                paginationPages={whitelistPaginationPages}
                onPrev={() => setPageWhitelistProposal((prev: number) => prev - 1)}
                onNext={() => setPageWhitelistProposal((prev: number) => prev + 1)}
              />
            )}
          </div>
        </>
      ) : null}

      <SectionTitle title="List of Whitelisted Addresses" />
      <div className="container-custom mx-auto !pt-14 !pb-16">
        <Table>
          <Table.Head>
            <Table.TR>
              <Table.TH>Name</Table.TH>
              <Table.TH className="!text-center">Address</Table.TH>
              <Table.TH></Table.TH>
            </Table.TR>
          </Table.Head>
          <Table.Body>
            {!isFetchingWhitelistAddress &&
              whitelistAddress?.rows?.map((item, key: number) => {
                return (
                  <Table.TR key={key}>
                    <Table.TD>{item.name}</Table.TD>
                    <Table.TD className="!text-end">{item.address}</Table.TD>
                    <Table.TD>
                      <div className="flex justify-end">
                        <div>
                          <Button
                            backgroundColor="#8f49fd"
                            className="w-[36px] p-0 text-white"
                            label=""
                            notAllowed={!hasUserWalletManagerRole}
                            icon={<CloseIcon />}
                            onClick={() =>
                              hasUserWalletManagerRole
                                ? setModalData({
                                    open: true,
                                    title: "Confirm address removal",
                                    description:
                                      "This address will no longer be available for withdrawals and will need to be re-whitelisted once removed.",
                                    payload: {
                                      id: item.id as string,
                                      actionType: Action.REMOVE,
                                      address: item.address,
                                      label: item.name,
                                    },
                                  })
                                : null
                            }
                          />
                        </div>
                      </div>
                    </Table.TD>
                  </Table.TR>
                );
              })}

            {whitelistAddress?.count === 0 ? (
              <Table.TR>
                <Table.TD colSpan={5} className="relative text-center !h-[150px]">
                  No results.
                </Table.TD>
              </Table.TR>
            ) : null}
            {isFetchingWhitelistAddress && (
              <Table.Loader>
                <Table.TD colSpan={4} className="relative !h-[865px]">
                  <Loader />
                </Table.TD>
              </Table.Loader>
            )}
          </Table.Body>
        </Table>
        {whitelistAddressPaginationPages > 1 && (
          <Pagination
            page={pageWhitelistAddress}
            paginationPages={whitelistAddressPaginationPages}
            onPrev={() => setPageWhitelistAddress((prev: number) => prev - 1)}
            onNext={() => setPageWhitelistAddress((prev: number) => prev + 1)}
          />
        )}
      </div>

      <Modal open={modalData.open} onClose={() => setModalData(initModalData)}>
        <div className="flex items-center gap-[10px] mb-8">
          <h1 className="font-pp-neue-machina font-semibold text-[30px]">{modalData.title}</h1>
        </div>
        <div className="font-pp-neue-machina">{modalData.description}</div>
        <div className="flex gap-4 mt-10">
          <Button
            label="Cancel"
            backgroundColor="#F9F5FF"
            color="#8F49FD"
            className="py-[8px] text-[14px] font-inter font-medium"
            onClick={() => setModalData(initModalData)}
          />
          <Button
            label="Confirm"
            backgroundColor="#8F49FD"
            color="#ffffff"
            className="py-[8px] text-[14px] font-inter font-medium"
            onClick={() => (hasUserWalletManagerRole ? handleConfirmAction() : null)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default WhiteListAddress;
