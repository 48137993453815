import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { addDays, addYears, subDays } from "date-fns";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ROUTE_ADMIN_SYNTHETIC_OPPORTUNITY_DETAILS } from "../../../../app/constants";
import { ReqCreateOpportunityV2Dto, ReqUpdateOpportunityV2Dto } from "../../../../app/model/api";
import {
  useMutationCreateVirtualOpportunity,
  useMutationUpdateOpportunityV2,
} from "../../../../app/query/useMutationAdmin";
import { MuiFullscreenLoadingBackdrop } from "../../../../components/atoms/MuiCircularPercentage/MuiLoadingBackdrop";
import { useState } from "react";
import { allowDemoHandling } from "../../../../app/config/auth0/config";

const MIN_DATE = addDays(new Date(), 1);
const MAX_DATE = addYears(new Date(), 2);
interface FormData {
  readonly requiredHashPowerForCompletionPhPerS: number;
  readonly initialCollateralPercentage: number;
  readonly maximumAmountOfLiquidityBtc: number;
  readonly fundingDeadline: Date;
  readonly durationOfAgreementDays: number;
  readonly expectedReturnPercentage: number;
  readonly structuringFeePercentage: number;
  readonly managementFeePercentage: number;
  readonly demoStartDate: Date | undefined | null;
}
const ERROR_NONZERO_INTEGER_PERCENTAGE = "This value has to be a number between 1 and 100!";
const YupNonzeroIntegerPercentage = yup
  .number()
  .typeError(ERROR_NONZERO_INTEGER_PERCENTAGE)
  .required(ERROR_NONZERO_INTEGER_PERCENTAGE)
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(1, ERROR_NONZERO_INTEGER_PERCENTAGE)
  .max(100, ERROR_NONZERO_INTEGER_PERCENTAGE)
  .integer(ERROR_NONZERO_INTEGER_PERCENTAGE);

const ERROR_PERCENTAGE = "This value has to be a number between 0 and 100!";
const YupPercentage = yup
  .number()
  .typeError(ERROR_PERCENTAGE)
  .required(ERROR_PERCENTAGE)
  .transform((value) => (Number.isNaN(value) ? null : value));

const YupPositiveNumber = yup
  .number()
  .typeError("This value has to be a positive number!")
  .positive("This value has to be a positive number!")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .required("This value has to be a positive number!");

const YupFormSchema = yup
  .object({})
  .shape({
    requiredHashPowerForCompletionPhPerS: YupPositiveNumber,
    initialCollateralPercentage: YupNonzeroIntegerPercentage,
    maximumAmountOfLiquidityBtc: YupPositiveNumber,
    fundingDeadline: yup
      .date()
      .typeError("This must be a valid future date!")
      .min(MIN_DATE, "This must be a valid future date!")
      .max(MAX_DATE, "This value can't be more than 2 years into the future.")
      .required("This value is required!"),
    durationOfAgreementDays: YupPositiveNumber.integer("This value has to be a positive integer!"),
    expectedReturnPercentage: YupPercentage,
    structuringFeePercentage: YupPercentage,
    managementFeePercentage: YupPercentage,
    demoStartDate: yup.date().nullable().optional(),
  })
  .required();

export const SyntheticStreamingForm = ({
  minerId,
  opportunity,
  triggerRefetch,
}: {
  minerId: string;
  opportunity?: ReqUpdateOpportunityV2Dto & { isDemo?: Date };
  triggerRefetch: () => void;
}) => {
  const navigate = useNavigate();

  const showDemoOption = allowDemoHandling && (!opportunity || opportunity.isDemo);
  const [isDemo, setIsDemo] = useState<boolean>();

  const {
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    register,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(YupFormSchema),
    defaultValues: opportunity
      ? {
          requiredHashPowerForCompletionPhPerS: opportunity.requiredHashPowerForCompletionPhPerS,
          initialCollateralPercentage: opportunity.initialCollateralPercentage,
          maximumAmountOfLiquidityBtc: opportunity.maximumAmountOfLiquidityBtc,
          fundingDeadline: new Date(opportunity.fundingDeadline),
          durationOfAgreementDays: opportunity.durationOfAgreementDays,
          expectedReturnPercentage: opportunity.expectedReturnPercentage,
          structuringFeePercentage: opportunity.structuringFeePercentage,
          managementFeePercentage: opportunity.managementFeePercentage,
        }
      : {
          fundingDeadline: addDays(new Date(), 1),
          // set some default values in non-prod environments to make it easier
          requiredHashPowerForCompletionPhPerS: allowDemoHandling ? 5 : undefined,
          initialCollateralPercentage: allowDemoHandling ? 5 : undefined,
          maximumAmountOfLiquidityBtc: allowDemoHandling ? 5 : undefined,
          durationOfAgreementDays: allowDemoHandling ? 45 : undefined,
          expectedReturnPercentage: allowDemoHandling ? 5 : undefined,
          structuringFeePercentage: allowDemoHandling ? 1.5 : undefined,
          managementFeePercentage: allowDemoHandling ? 2 : undefined,
          demoStartDate: subDays(new Date(), 90),
        },
  });

  const fundingDeadline = watch("fundingDeadline");
  const demoStartDate = watch("demoStartDate");

  const { mutateAsync: createOpportunity, isLoading: isCreatingOpportunity } = useMutationCreateVirtualOpportunity();
  const { mutateAsync: updateOpportunity, isLoading: isUpdatingOpportunity } = useMutationUpdateOpportunityV2();

  const isSavingOpportunity = isCreatingOpportunity || isUpdatingOpportunity;

  const handleCreate = async (data: FormData) => {
    const request: ReqCreateOpportunityV2Dto = {
      minerId: minerId,
      requiredHashPowerForCompletionPhPerS: data.requiredHashPowerForCompletionPhPerS,
      initialCollateralPercentage: data.initialCollateralPercentage,
      maximumAmountOfLiquidityBtc: data.maximumAmountOfLiquidityBtc,
      fundingDeadline: data.fundingDeadline.toISOString(),
      durationOfAgreementDays: data.durationOfAgreementDays,
      expectedReturnPercentage: data.expectedReturnPercentage,
      structuringFeePercentage: data.structuringFeePercentage,
      managementFeePercentage: data.managementFeePercentage,
      demoStartDate: isDemo && showDemoOption && demoStartDate ? demoStartDate.toISOString() : undefined,
    };

    try {
      const result = await createOpportunity(request);
      toast.success("Your updates have been saved successfully.");
      navigate(ROUTE_ADMIN_SYNTHETIC_OPPORTUNITY_DETAILS.replace(":id", result.data.id));
    } catch (err) {
      toast.error("There has been an issue while trying to save your changes. Please try again.");
    }
  };

  const handleUpdate = async (opportunityId: string, data: FormData) => {
    const request: ReqUpdateOpportunityV2Dto = {
      opportunityId: opportunityId,
      requiredHashPowerForCompletionPhPerS: data.requiredHashPowerForCompletionPhPerS,
      initialCollateralPercentage: data.initialCollateralPercentage,
      maximumAmountOfLiquidityBtc: data.maximumAmountOfLiquidityBtc,
      fundingDeadline: data.fundingDeadline.toISOString(),
      durationOfAgreementDays: data.durationOfAgreementDays,
      expectedReturnPercentage: data.expectedReturnPercentage,
      structuringFeePercentage: data.structuringFeePercentage,
      managementFeePercentage: data.managementFeePercentage,
    };

    try {
      await updateOpportunity(request);
      toast.success("Your updates have been saved successfully.");
      triggerRefetch();
    } catch (err) {
      toast.error("There has been an issue while trying to save your changes. Please try again." + err);
    }
  };

  const onSubmit = (data: FormData) => {
    if (opportunity?.opportunityId) {
      handleUpdate(opportunity.opportunityId, data);
    } else {
      handleCreate(data);
    }
  };

  const isExistingOpportunity = !!opportunity?.opportunityId;

  return (
    <Paper sx={{ padding: 2, boxShadow: 4 }}>
      <MuiFullscreenLoadingBackdrop isOpen={isSavingOpportunity} />
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Grid container item spacing={2}>
          <Grid container item>
            <Typography variant="subtitle1">
              {isExistingOpportunity ? "Update opportunity" : "Create opportunity"}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="averagePrice"
                label={"Required hashrate for 100% of opportunity"}
                placeholder="5"
                {...register("requiredHashPowerForCompletionPhPerS")}
                fullWidth
                variant="outlined"
                error={!!errors.requiredHashPowerForCompletionPhPerS}
                helperText={
                  errors.requiredHashPowerForCompletionPhPerS && (
                    <Box>{errors.requiredHashPowerForCompletionPhPerS.message}</Box>
                  )
                }
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.01",
                  },
                  endAdornment: <InputAdornment position="end">PH/s</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <TextField
                id="averagePrice"
                label={"Minimum collateral as % of initial liquidity"}
                placeholder="5"
                {...register("initialCollateralPercentage")}
                fullWidth
                variant="outlined"
                error={!!errors.initialCollateralPercentage}
                helperText={
                  errors.initialCollateralPercentage && <Box>{errors.initialCollateralPercentage.message}</Box>
                }
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.01",
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="maximumAmountOfLiquidityBtc"
                label={"Max amount of liquidity"}
                placeholder="5"
                {...register("maximumAmountOfLiquidityBtc")}
                fullWidth
                variant="outlined"
                error={!!errors.maximumAmountOfLiquidityBtc}
                helperText={
                  errors.maximumAmountOfLiquidityBtc && <Box>{errors.maximumAmountOfLiquidityBtc.message}</Box>
                }
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.001",
                  },
                  endAdornment: <InputAdornment position="end">BTC</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Funding deadline"
                  format="dd/MM/yyyy"
                  value={fundingDeadline}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  onChange={(e) => {
                    setValue("fundingDeadline", e ?? addDays(new Date(), 1), { shouldDirty: true });
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      helperText: errors.fundingDeadline && errors.fundingDeadline.message,
                      error: !!errors.fundingDeadline,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="durationOfAgreementDays"
                label={"Duration of agreement"}
                placeholder="5"
                {...register("durationOfAgreementDays")}
                fullWidth
                variant="outlined"
                error={!!errors.durationOfAgreementDays}
                helperText={errors.durationOfAgreementDays && <Box>{errors.durationOfAgreementDays.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "1",
                  },
                  endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <TextField
                id="expectedReturnPercentage"
                label={"Expected annualized rate of return"}
                placeholder="5"
                {...register("expectedReturnPercentage")}
                fullWidth
                variant="outlined"
                error={!!errors.expectedReturnPercentage}
                helperText={errors.expectedReturnPercentage && <Box>{errors.expectedReturnPercentage.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.01",
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="structuringFeePercentage"
                label={"Structuring fee percentage"}
                placeholder="1.5"
                {...register("structuringFeePercentage")}
                fullWidth
                variant="outlined"
                error={!!errors.structuringFeePercentage}
                helperText={errors.structuringFeePercentage && <Box>{errors.structuringFeePercentage.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.01",
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <TextField
                id="managementFeePercentage"
                label={"Management fee percentage"}
                placeholder="2"
                {...register("managementFeePercentage")}
                fullWidth
                variant="outlined"
                error={!!errors.managementFeePercentage}
                helperText={errors.managementFeePercentage && <Box>{errors.managementFeePercentage.message}</Box>}
                onWheel={(evt) => {
                  (evt.target as HTMLElement).blur(); // disable edit by scroll
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    step: "0.01",
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            {showDemoOption ? (
              <Grid container item xs={12} md={12} alignItems="center" justifyContent={"flex-start"} pt={3}>
                <Stack spacing={1}>
                  <FormControlLabel
                    label="Is demo opportunity"
                    control={<Checkbox checked={isDemo} onChange={(_e, value) => setIsDemo(value)} />}
                  />

                  {isDemo ? (
                    <Stack>
                      <Box pt={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Demo start date"
                            format="dd/MM/yyyy"
                            value={demoStartDate}
                            minDate={subDays(new Date(), 120)}
                            maxDate={subDays(new Date(), 30)}
                            onChange={(e) => {
                              setValue("demoStartDate", e ?? subDays(new Date(), 120), { shouldDirty: true });
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                                error: !!errors.demoStartDate,
                                helperText: errors.demoStartDate && errors.demoStartDate.message,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Stack pt={2}>
                        <Typography variant="caption">
                          Demo opportunities are not driven by automatic checks, instead by manually added events.
                        </Typography>
                        <Typography variant="caption">
                          Demo opportunities are always created in the past, so that we can use past data such as
                          computed hash prices..
                        </Typography>
                      </Stack>
                    </Stack>
                  ) : null}
                </Stack>
              </Grid>
            ) : null}
          </Grid>
          <Grid container item xs={12} md={12} justifyContent={"flex-end"}>
            <Tooltip title={isDirty ? "" : "Please fill in the form to save."}>
              <span>
                <LoadingButton
                  size="large"
                  type="submit"
                  loading={isSavingOpportunity}
                  disabled={!isDirty && !showDemoOption}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
