import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { MINER_ORGANIZATION_BASIC_INFO_ROUTE, ORGANIZATION_DETAILS_ROUTE } from "../../../../app/constants";
import { useGetAdminPhysicalOpportunityDetails } from "../../../../app/query/useQueryGetAdmin";
import { formatBtcAmount } from "../../../Loans/commons/utils";
import { StyledTableRow } from "../../commons";
import { PhysicalStreamingForm } from "./PhysicalStreamingForm";

export const AdminPhysicalOpportunityEdit = ({ opportunityId }: { opportunityId: string }) => {
  const [destroyKey, setDestroyKey] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);

  const { data, isFetching, refetch } = useGetAdminPhysicalOpportunityDetails(opportunityId ?? "");

  const onRefetch = () => {
    refetch();
    setDestroyKey(!destroyKey); // force the component to be rebuilt
    setEdit(false);
  };

  const payload = data?.data;
  const isEditable = payload?.status === "Draft";

  const minerPath = payload?.minerId ? MINER_ORGANIZATION_BASIC_INFO_ROUTE.replace(":id", payload?.minerId ?? "") : "";

  const lpPath = payload?.lpId ? ORGANIZATION_DETAILS_ROUTE.replace(":id", payload?.lpId ?? "") : "";

  const infoRows: { title: string; value: JSX.Element | null }[] = [
    {
      title: "Miner",
      value: (
        <Button
          sx={{ height: 20, padding: 0, "&:hover": { textDecoration: "underline" } }}
          target="_blank"
          rel="noreferrer"
          href={minerPath}
          variant="text"
        >
          {payload?.minerCompanyName ?? ""}
        </Button>
      ),
    },
    {
      title: "LP",
      value: (
        <Button
          sx={{ height: 20, padding: 0, "&:hover": { textDecoration: "underline" } }}
          target="_blank"
          rel="noreferrer"
          href={lpPath}
          variant="text"
        >
          {payload?.lpName ?? ""}
        </Button>
      ),
    },
    {
      title: "Collateral Address",
      value: <Typography noWrap>{payload?.collateralAddress ?? "Not yet created"}</Typography>,
    },
  ];

  return isFetching || !payload ? (
    <Stack>
      <Skeleton width="100%" height={100}></Skeleton>
      <Skeleton width="100%" height={200}></Skeleton>
    </Stack>
  ) : (
    <Box width={"100%"} key={destroyKey.toString()} mt={1}>
      <Grid container item xs={12} mt={2}>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <StyledTableRow key={"th-op-info-0"}>
                <TableCell size="small" align="center">
                  <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell size="small" align="center">
                  <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                    Duration
                  </Typography>
                </TableCell>
                <TableCell size="small" align="center">
                  <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                    Start Date
                  </Typography>
                </TableCell>
                <TableCell size="small" align="center">
                  <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                    Principal Amount
                  </Typography>
                </TableCell>
                <TableCell size="small" align="center">
                  <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                    Hashrate
                  </Typography>
                </TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <TableRow key={"row-dates-info-1"}>
                <TableCell size="small" align="center">
                  {payload.status}
                </TableCell>
                <TableCell size="small" align="center">
                  {payload.durationDays} days
                </TableCell>
                <TableCell size="small" align="center">
                  {format(new Date(payload.startDate), "dd MMM yyyy")}
                </TableCell>
                <TableCell size="small" align="center">
                  {formatBtcAmount(payload.principalAmountSatoshi)}
                </TableCell>
                <TableCell size="small" align="center">
                  {payload.fullHashrateThPerSecond / 1000} PH/s
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container item alignItems={"center"}>
        <Grid container item xs={12} md={6} alignItems={"center"} spacing={1} pl={1} mt={2}>
          {isEditable && !edit ? (
            <Button onClick={() => setEdit(true)} color="primary">
              <EditNoteIcon sx={{ mr: 1 }} />
              Edit
            </Button>
          ) : null}
          {edit ? (
            <Button onClick={() => setEdit(false)} color="primary">
              Cancel
            </Button>
          ) : null}
        </Grid>
      </Grid>

      {isEditable && edit ? (
        <Box mt={2}>
          <PhysicalStreamingForm minerId={payload.minerId} opportunity={payload} triggerRefetch={onRefetch} />
        </Box>
      ) : (
        <Grid container item xs={12} mt={2} spacing={1} sx={{ width: "100%" }}>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <TableCell size="small" align="left" width={300}>
                      <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                        Collateral Percentage
                      </Typography>
                    </TableCell>
                    <TableCell size="small" align="left">
                      {payload.collateralPercentage}%
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell size="small" align="left" width={300}>
                      <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                        Expected Return
                      </Typography>
                    </TableCell>
                    <TableCell size="small" align="left">
                      {payload.expectedReturnPercentage}%
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell size="small" align="left" width={300}>
                      <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                        Structuring Fee
                      </Typography>
                    </TableCell>
                    <TableCell size="small" align="left">
                      {payload.structuringFeePercentage}%
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell size="small" align="left" width={300}>
                      <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                        Management Fee
                      </Typography>
                    </TableCell>
                    <TableCell size="small" align="left">
                      {payload.managementFeePercentage}%
                    </TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {infoRows.map((row, index) => (
                    <StyledTableRow key={"row-op-info-" + index}>
                      <TableCell size="small" align="left" width={300}>
                        <Typography variant="subtitle2" fontWeight={"fontWeightBold"}>
                          {row.title}
                        </Typography>
                      </TableCell>
                      <TableCell size="small" align="left">
                        {row.value}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
