import { useQuery } from "@tanstack/react-query";
import { apig } from "../config/query/clientgen";
import { RequestParams } from "../model/api";

const getMinerOpportunity = async (id: string, params: RequestParams) => {
  return (await apig.admin.getAdminOpportunity(id, params)).data;
};

const getOpportunitySummary = async (id: string) => {
  return (await apig.miner.getMinerOpportunitySummary(id)).data;
};

const getMinerProxyHashrate = async () => {
  return (await apig.miningProxy.getProxyHashrate()).data;
};

const getMinerProxyMiningAddress = async () => {
  return (await apig.miningProxy.getProxyMiningAddress()).data;
};

const getMinerPoolCredentials = async () => {
  return (await apig.miningProxy.getPoolCredentials()).data;
};

export const useGetMinerOpportunity = (id: string) => {
  return useQuery([`opportunity_${id}`], () => getMinerOpportunity(id, {}));
};

export const useGetMinerOpportunitySummary = (id: string, enabled: boolean) => {
  return useQuery([`opportunity_${id}_summary`], () => getOpportunitySummary(id), {
    refetchOnMount: "always",
    enabled,
  });
};

export const useGetMinerProxyHashrate = () => {
  return useQuery(["miner_proxy_hashrate"], () => getMinerProxyHashrate(), {
    refetchOnMount: "always",
    refetchOnWindowFocus: false,
  });
};

export const useGetMinerProxyMiningAddress = () => {
  return useQuery(["miner_proxy_mining_address"], () => getMinerProxyMiningAddress(), {
    refetchOnMount: "always",
    refetchOnWindowFocus: false,
  });
};

export const useGetMinerPoolCredentials = () => {
  return useQuery(["miner_pool_credentials"], () => getMinerPoolCredentials(), {
    refetchOnMount: "always",
    refetchOnWindowFocus: false,
  });
};
