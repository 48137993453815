import { TabContext, TabPanel } from "@mui/lab";
import { Box, Skeleton, Stack, Tab, Tabs } from "@mui/material";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ADMIN_PHYSICAL_OPPORTUNITY_TABS } from "../../../../app/constants/sectionTabsPhysicalOpportunity";

export const AdminPhysicalOpportunityTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: opportunityId } = useParams();
  const currentTab = location.pathname;

  const getPathWithIdValue = (path: string): string => {
    return path.replace(":id", opportunityId ?? "");
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <Box width={"100%"} display="flex" justifyContent={"center"}>
      <Stack width={"100%"} maxWidth={1600} sx={{ background: "white", boxShadow: 1 }}>
        <TabContext value={currentTab}>
          <Box display="flex" alignItems={"Center"}>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={currentTab}
              onChange={handleChange}
              aria-label="physical-opportunity-details-tabs"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {ADMIN_PHYSICAL_OPPORTUNITY_TABS.map((entry) => (
                <Tab
                  key={"tb-pop-details-" + entry.name}
                  label={entry.name}
                  value={getPathWithIdValue(entry.path)}
                  sx={{ fontWeight: "fontWeightBold" }}
                />
              ))}
            </Tabs>
          </Box>
          {!opportunityId ? (
            <Skeleton height={200} width={"100%"}></Skeleton>
          ) : (
            ADMIN_PHYSICAL_OPPORTUNITY_TABS.map((entry) => (
              <TabPanel key={"tbpanel-pop-" + entry.name} value={getPathWithIdValue(entry.path)} sx={{ mt: -2 }}>
                {entry.tabComponent(opportunityId)}
              </TabPanel>
            ))
          )}
        </TabContext>
      </Stack>
    </Box>
  );
};
