import { useQueryClient } from "@tanstack/react-query";
import Decimal from "decimal.js";
import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ERROR_DEFAULT_MESSAGE, SATOSHIS_TO_BTC } from "../../app/constants";
import { amountFormatSatoshisToBTC, getOpportunityStatusClass } from "../../app/helper/utils";
import {
  AuthUserResponseDto,
  GetMinerOpportunityHistoryTxsParamsTxtypesEnum as TransactionType,
  OpportunitiesDetailsCollateralStatusEnum,
  ProtocolWalletDtoWalletTypeEnum,
  StreamingBasicStatusEnum,
} from "../../app/model/api";
import { Permission } from "../../app/model/user.type";
import { useGetQueryCollateralInfo } from "../../app/query/useGetQueryOpportunity";
import { useMutationCollateralToOpportunity } from "../../app/query/useMutationWallet";
import { useGetMinerOpportunity } from "../../app/query/useQueryGetMiner";
import { useGetQueryWalletFee } from "../../app/query/useQueryGetWallet";
import Button from "../../components/atoms/Button";
import InputBTC from "../../components/atoms/InputBTC";
import SectionTitle from "../../components/atoms/SectionTitle";
import BoxCompanyInfo from "../../components/organisms/BoxOpportunityInfo";
import HeroOpportunityDetailsMiner from "../../components/organisms/HeroOpportunityDetailsMiner";
import { TransactionHistoryTable } from "../../components/organisms/TransactionHistoryTable";
import TransferConfirmationModal from "../../components/organisms/TransferConfirmationModal";
import TransferModal from "../../components/organisms/TransferModal";
import "./style.scss";

const OpportunityDetailsMiner = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryState<AuthUserResponseDto>(["user"]);

  const [transferModal, setTransferModal] = useState<boolean>(false);
  const [confirmationTransferModal, setConfirmationTransferModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);

  const { data: walletFee } = useGetQueryWalletFee();
  const { data: minerData } = useGetMinerOpportunity(String(id));
  const { data: collaterralInfo } = useGetQueryCollateralInfo(String(id));
  const {
    mutateAsync: collateralToOpportunity,
    data: transferCoinResponse,
    isLoading: isLoadingTransfer,
  } = useMutationCollateralToOpportunity();

  const { opportunityDetails } = minerData || {};
  const { opportunityBasic, collateralStatus } = opportunityDetails || {};
  const { idNum, status } = opportunityBasic || {};
  const toAddress =
    minerData?.opportunityDetails?.protocolWallets?.find(
      (item) => item.walletType === ProtocolWalletDtoWalletTypeEnum.CollateralVault
    )?.walletAddress ?? "unknown";

  const handleCollateralToOpportunity = async () => {
    try {
      await collateralToOpportunity({ id: id as string, amount: SATOSHIS_TO_BTC.mul(amount).round().toString() });

      setTransferModal(false);
      setConfirmationTransferModal(true);
    } catch (error) {
      toast.error(ERROR_DEFAULT_MESSAGE);
    }
  };

  const isDisabled =
    status === undefined ||
    ![
      StreamingBasicStatusEnum.Active,
      StreamingBasicStatusEnum.AwaitingCollateral,
      StreamingBasicStatusEnum.AwaitingFunding,
    ].includes(status);

  const canUserDeposit = user?.data?.permissions?.includes(Permission.CREATE_COLLATERAL_DEPOSIT) || false;
  const maxSatoshis = new Decimal(user?.data?.maximumSpendableSatoshis || 0)
    .minus(user?.data?.reservedBalanceSatoshis || 0)
    .minus(walletFee?.fee || 0);
  const maxBtc = maxSatoshis.gt(0) ? maxSatoshis.div(SATOSHIS_TO_BTC).toNumber() : 0;
  const isOverAmount = amount ? new Decimal(amount).greaterThan(new Decimal(maxBtc)) : false;

  return (
    <>
      <HeroOpportunityDetailsMiner />
      <SectionTitle title={`Opportunity #${idNum}`} />
      <div>
        <div className="container-custom mx-auto !pt-14 !pb-16">
          <div className="bg-white p-3 mb-10 grid grid-cols-3 gap-3 items-center">
            {status === StreamingBasicStatusEnum.Active && collateralStatus ? (
              <>
                <div>
                  <div
                    className={`grid grid-cols-2 text-center p-1 ${
                      collateralStatus === OpportunitiesDetailsCollateralStatusEnum.Sufficient
                        ? "bg-green-100"
                        : "bg-red-100"
                    }`}
                  >
                    <div
                      className={`p-2 ${
                        collateralStatus === OpportunitiesDetailsCollateralStatusEnum.Sufficient
                          ? "bg-light-green text-green-primary"
                          : "bg-red-50 text-red-500"
                      }`}
                    >
                      {status}
                    </div>
                    <div
                      className={`p-2 ${
                        collateralStatus === OpportunitiesDetailsCollateralStatusEnum.Sufficient
                          ? "text-green-primary"
                          : "text-red-300"
                      }`}
                    >
                      {collateralStatus}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className={`status-box ${getOpportunityStatusClass(status)}`}>
                <p className="uppercase font-pp-neue-machina font-bold text-sm">{status}</p>
              </div>
            )}
            <div className="uppercase font-pp-neue-machina">
              <strong className="text-[13px]">Collateral Balance</strong>
              <span className="bg-red-50 p-2 ml-2">
                {amountFormatSatoshisToBTC(collaterralInfo?.currentBalanceSatoshis || 0)} BTC /{" "}
                {amountFormatSatoshisToBTC(collaterralInfo?.collateralRequirementSatoshis || 0)} BTC
              </span>
            </div>
            <div className="uppercase font-pp-neue-machina">
              {collaterralInfo?.missingCollateralSatoshis !== undefined &&
              collaterralInfo?.missingCollateralSatoshis > 0 ? (
                <>
                  <strong className="text-[13px]">Additional collateral required</strong>
                  <span className="bg-red-50 p-2 ml-2">
                    {amountFormatSatoshisToBTC(collaterralInfo?.missingCollateralSatoshis || 0)} BTC
                  </span>
                </>
              ) : (
                <strong className="text-[13px]">No additional collateral required</strong>
              )}
            </div>
          </div>
          {collaterralInfo?.liquidityDeliveryRequirement !== undefined && (
            <div className="bg-white p-3 mb-10 grid grid-cols-3 gap-4 items-center">
              <>
                <div className={`status-box ${getOpportunityStatusClass(status)}`}>
                  <p className="uppercase font-pp-neue-machina font-bold text-sm">
                    Requirements for liquidity delivery
                  </p>
                </div>
                <div className="uppercase font-pp-neue-machina">
                  {collaterralInfo?.liquidityDeliveryRequirement?.collateralRequirementSatoshis > 0 ? (
                    <>
                      <strong className="text-[13px]">Additional collateral required</strong>
                      <span className="bg-red-50 p-2 ml-2">
                        {amountFormatSatoshisToBTC(
                          collaterralInfo?.liquidityDeliveryRequirement?.missingCollateralSatoshis || 0
                        )}{" "}
                        BTC
                      </span>
                    </>
                  ) : (
                    <strong className="text-[13px]">No additional collateral required</strong>
                  )}
                </div>
                <div className="uppercase font-pp-neue-machina">
                  <strong className="text-[13px]">Additional hashrate required</strong>
                  <span className="bg-red-50 p-2 ml-2">
                    {new Decimal(
                      collaterralInfo?.liquidityDeliveryRequirement?.additionalHashrateRequiredThPerSecond || 0
                    )
                      .div(1000)
                      .toString()}{" "}
                    PH/S{" "}
                  </span>
                </div>
              </>
            </div>
          )}

          <div className="grid grid-cols-3 grid-row-2 gap-5 mb-6">
            <div className="col-span-2 row-span-2">
              <SectionTitle title="Opportunity Information" />
              {minerData?.opportunityDetails && <BoxCompanyInfo opportunityDetails={minerData?.opportunityDetails} />}
            </div>
            <div className="bg-white">
              <SectionTitle title="Deposit Collateral" />
              <div className="p-6">
                <p>
                  <strong>
                    Collateral requirement -{" "}
                    {amountFormatSatoshisToBTC(collaterralInfo?.missingCollateralSatoshis || 0)} BTC
                  </strong>
                </p>
                <p>On-chain collateral needs to be posted to initiate the liquidity transfer</p>

                <div className="relative mb-3">
                  <InputBTC
                    showTooltip={!canUserDeposit}
                    value={SATOSHIS_TO_BTC.mul(amount).toString()}
                    onChange={(value) => setAmount(+value)}
                    fee={walletFee?.fee}
                    disabled={isDisabled || !canUserDeposit}
                  />
                  <Button
                    type="button"
                    label="Deposit"
                    className="w-full px-1 text-white mb-6"
                    backgroundColor="#8F49FD"
                    onClick={() => (amount > 0 ? (canUserDeposit ? setTransferModal(true) : null) : null)}
                    notAllowed={!(amount > 0) || isDisabled || !canUserDeposit || isOverAmount}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-full">
              <TransactionHistoryTable
                allowedTransactionTypes={[
                  TransactionType.CollateralDeposit,
                  TransactionType.CollateralRelease,
                  TransactionType.RewardDelivery,
                  TransactionType.LiquidityDelivery,
                  TransactionType.ExcessRewardRelease,
                  TransactionType.CollateralLiquidation,
                ]}
              />
            </div>
          </div>
        </div>

        {transferModal ? (
          <TransferModal
            data={{
              date: moment().toISOString() as string,
              fromAddress: user?.data?.walletAddress as string,
              toAddress,
              deposit: amount,
              fee: walletFee?.fee || 0,
            }}
            disableSubmitButton={isLoadingTransfer || !canUserDeposit}
            open={transferModal}
            onClose={() => setTransferModal(false)}
            onSubmit={() => (canUserDeposit ? handleCollateralToOpportunity() : null)}
          />
        ) : null}

        {confirmationTransferModal ? (
          <TransferConfirmationModal
            open={confirmationTransferModal}
            data={{
              fromAddress: user?.data?.walletAddress as string,
              toAddress,
              depoosit: amount,
              fee: walletFee?.fee || 0,
              txHash: transferCoinResponse?.data?.txId as string,
              txUrl: transferCoinResponse?.data?.url as string,
            }}
            onClose={() => {
              setConfirmationTransferModal(false);
              setAmount(0);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default OpportunityDetailsMiner;
