import { Stack } from "@mui/material";
import {
  ROUTE_ADMIN_PHYSICAL_OPPORTUNITY_DETAILS,
  ROUTE_ADMIN_PHYSICAL_OPPORTUNITY_LIQUIDITY,
  ROUTE_ADMIN_PHYSICAL_OPPORTUNITY_REWARDS,
} from ".";
import { HeroOpportunityDetails } from "../../components/organisms/HeroOpportunityDetails/HeroOpportunityDetails";
import LiquidityProviderTable from "../../components/organisms/LiquidityProviderTable";
import { TransactionHistoryTable } from "../../components/organisms/TransactionHistoryTable";
import { AdminPhysicalOpportunityEdit } from "../../pages/Admin/MinerDetails/Opportunity/AdminPhysicalOpportunityEdit";
import { AdminPhysicalOpportunityRewards } from "../../pages/Admin/MinerDetails/Opportunity/AdminPhysicalOpportunityRewards";

export const ADMIN_PHYSICAL_OPPORTUNITY_TABS: {
  name: string;
  path: string;
  tabComponent: (id: string) => JSX.Element;
}[] = [
  {
    name: "Physical opportunity details",
    path: ROUTE_ADMIN_PHYSICAL_OPPORTUNITY_DETAILS,
    tabComponent: (opportunityId: string) => <AdminPhysicalOpportunityEdit opportunityId={opportunityId} />,
  },
  {
    name: "Liquidity info",
    path: ROUTE_ADMIN_PHYSICAL_OPPORTUNITY_LIQUIDITY,
    tabComponent: (_opportunityId: string) => (
      <Stack spacing={3}>
        <HeroOpportunityDetails />
        <LiquidityProviderTable />
        <TransactionHistoryTable />
      </Stack>
    ),
  },
  {
    name: "Rewards",
    path: ROUTE_ADMIN_PHYSICAL_OPPORTUNITY_REWARDS,
    tabComponent: (opportunityId: string) => <AdminPhysicalOpportunityRewards opportunityId={opportunityId} />,
  },
];
